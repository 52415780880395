import React from "react";
import Layout from "../components/layout-maintenance";

const IndexPage = () => {

	var maintenanceSeo = { metaTitle:'Coming soon', metaDescription:'Landing page of Nocturnal E-sports which is currently under construction.'};

	return (
		<Layout page="index" seo={maintenanceSeo}>
			<section id="banner">
				<div className="inner">
					<h2 className="mt-5">In maintenance</h2>
					<p>We are currently working on an update, please come back soon.</p>
				</div>
			</section>
		</Layout>
	);
};

export default IndexPage;
