import React from "react";
import PropTypes from "prop-types";
import { Helmet } from 'react-helmet'
import { StaticQuery, graphql } from "gatsby";

import Seo from "./seo";

import '../assets/bootstrap-5.x/css/bootstrap.min.css';
import '../assets/fontawesome-5.x/css/all.min.css';
import '../assets/css/main-theme.css';
import '../assets/css/main.css';

import 'jquery';
import 'react-popper';

const Layout = ({ children, seo, page }) => (
	<StaticQuery
		query={graphql`
			query {
				strapiHome {
					seo {
						metaTitle
						metaDescription
						shareImage {
							url
						}
					}
				}
				strapiGlobal {
					footerTitleLeft
					socialmedia {
						platform
						url
					}
					footerTitleRight
					footerTextRight
				}
			}
		`}
		render={(data) => (
			<>
			<Helmet
			noscript={[{
				link: {
					rel: "stylesheet",
					href: "../assets/css/noscript.css",
				},
			}]} />

			<Seo seo={seo} />
			<nav className="navbar fixed-top navbar-expand-md" id="navbarMain">
				<div className="container-fluid">
					<a className="navbar-brand d-flex align-items-center d-md-none logo-home" href="/"><img src="/images/nocturnal-logo-color.png" alt="Logo, Nocturnal E-Sports"/></a>
					<button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navMain" aria-controls="navMain" aria-expanded="false" aria-label="Toggle navigation">
						<i className="fas fa-bars"></i>
					</button>

					<div className="collapse navbar-collapse" id="navMain">
						<ul className="navbar-nav mx-auto mb-2 mb-lg-0">
							<li className="nav-item d-none d-md-inline logo-home">
								<a className="nav-link" href="/">
									<img src="/images/nocturnal-logo-color.png" alt="Logo, Nocturnal E-Sports"/>
								</a>
							</li>
						</ul>
					</div>
				</div>
			</nav>
			<div id="page-wrapper">

				{children}
						<section id="footer">
							<div className="inner d-flex justify-content-around">
								<div className="px-6 px-sm-0">
									<p className="title">{data.strapiGlobal.footerTitleLeft}</p>
									{data.strapiGlobal.socialmedia.length ?
										<div className="d-flex justify-content-around social">
											{data.strapiGlobal.socialmedia.map((socialmediaicon, i) => (
												<a href={socialmediaicon.url} key={socialmediaicon.platform} target="_blank" rel="noreferrer">
													{(() => {
														switch (socialmediaicon.platform) {
														case 'Twitter':
															return (<i className="fab fa-twitter-square"></i>);
														case 'Facebook':
															return (<i className="fab fa-facebook-square"></i>);
														case 'Instagram':
															return (<i className="fab fa-instagram-square"></i>);
														case 'Discord':
															return (<i className="fab fa-discord"></i>);
														case 'Youtube':
															return (<i className="fab fa-youtube-square"></i>);
														default:
															return (<i className="fas fa-external-link-square-alt"></i>);
													}})()}
												</a>
											))}
										</div>
									:
										''
									}
								</div>
								<div className="px-6 px-sm-0 pt-5 pt-sm-0">
									<p className="title">{data.strapiGlobal.footerTitleRight}</p>
									<p>info@nocturnal.lu</p>
								</div>
							</div>
							<div className="inner author">
								<div className="col-12 pb-3">
									<p>&copy; <a href="/">Nocturnal E-Sports</a>. All rights reserved</p>
								</div>
								<div className="col-12 d-flex justify-content-around">
									<p>Designed and developed by<br/><a href="https://www.dapit.lu" target="_blank" rel="noreferrer"><img width="65px" src="/images/dapit-logo-lightgray.png" alt="Logo, Dapit"/></a></p>
									<p>Design based on<br/><a href="https://html5up.net" target="_blank" rel="noreferrer">HTML5 up</a></p>
								</div>
							</div>

							<a id="toTop-button" href="#page-wrapper">
								<i className="fas fa-level-up-alt"></i>
								<div className="bg"></div>
							</a>
						</section>
			</div>
			</>

		)}
	/>
);

Layout.propTypes = {
	children: PropTypes.node.isRequired,
};

export default Layout;
